export const translation = {
  "connecting": "Povezujem...",
  "offline": {
    "title": "Opozorilo!",
    "message": "Kviz ni na voljo"
  },
  "video": {
    "tapToUnmute": "Vključi zvok",
    "tryVideoAgain": "Poskusite znova video"
  },
  "countdown": {
    "soon": "Kmalu",
    "aboutToStart": "Začenjamo kmalu...",
    "kickOff": "začnemo"
  },
  "join": {
    "instruction": "Pridruži se kvizu!",
    "joinInNow": "Pridruži se kvizu!",
    "screenName": "Koda",
    "enterScreenName": "Vnesi svojo kodo",
    "button": {
      "join": "Pridruži se",
      "next": "Naprej"
    },
    "chooseTeam": "Izberi ekipo",
    "hello": "Zdravo",
    "vs": "– vs –",
    "player": "Igralec",
    "players": "Igralci"
  },
  "form": {
    "name": "Ime",
    "email": "Elektronski naslov",
    "mobile": "Telefonska številka",
    "iAgreeToThe": "Strinjam se s <0>{{terms}}</0>",
    "terms": "pogoji rabe",
    "termsLink": "https://piing.events/terms-quiiz",
    "privacyPolicy": "Politika zasebnosti",
    "privacyPolicies": "Politike zasebnosti",
    "privacyLink": "https://piing.events/privacy",
    "ok": "OK"
  },
  "errorBoundary": {
    "connectionErrorTitle": "Oprosti, imamo težavo pri povezovanju.",
    "connectionError": "Prosim <0>{{tryAgain1}}</0>.",
    "notConnectionErrorTitle": "Nekaj je šlo narobe.",
    "notConnectionError": "Prosim <0>{{tryAgain2}}</0> or <1>{{contactUs}}</1> za prijavo napake.",
    "tryAgain1": "poskusi ponovno",
    "tryAgain2": "poskusi ponovno",
    "contactUs": "kontaktiraj nas",
    "emailSubject": "Prijavi težavo",
    "emailBody": "Prosim za opis težave"
  },
  "validation": {
    "nameTaken": "Koda je že zasedena!",
    "problemJoining": "Težava pri povezavi v kviz",
    "notReadyForPlayers": "Sodelovanje še ni mogoče",
    "checkName": "Preveri kodo",
    "checkEmail": "Preveri elektronski naslov",
    "checkMobile": "Preveri telefonsko številko",
  },
  "leaderboard": {
    "teamsLeaderboard": "Lestvica ekip",
    "playersLeaderboard": "Lestvica igralcev",
    "team": "Ekipa",
    "name": "Ime",
    "score": "Rezultat",
    "bonus": "Bonus",
    "wins": "zmaga!",
    "tab": {
      "topPlayers": "najboljši igralec",
      "topTeams": "Najboljša ekipa",
      "yourTeam": "Tvoja ekipa",
    }
  },
  "score": {
    "q": "Q",
    "correct": "Pravilno",
    "incorrect": "Napačno",
    "speedBonus": "Bonus točke",
    "team": "Ekipa",
    "overall": "Skupaj",
    "score": "Do",
    "rank": "Mesto",
  },
  "question": {
    "question": "Vprašanje",
    "milliseconds": "ms",
  }
};