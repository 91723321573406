import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translation as en } from './locale/en.js';
import { translation as de } from './locale/de.js';
import { translation as es } from './locale/es.js';
import { translation as sl } from './locale/sl.js';
import { translation as tr } from './locale/tr.js';
import { translation as pl } from './locale/pl.js';

const resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  },
  sl: {
    translation: sl
  },
  tr: {
    translation: tr
  },
  pl: {
    translation: pl
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    interpolation: {
      escapeValue: false, // react escapes by default
    },
  });

export const localisationSettings = {
  get language() {
    return i18n.language;
  },
  get locale() {
    return i18n.language.split('-')[0]; // the idea is to convert to ISO 639-1 language code
  }
}

export const setupI18N = (language) => {
  i18n.changeLanguage(language);
}

export default i18n;