import { action, computed, decorate, autorun, observable } from 'mobx';
import localStorage from 'mobx-localstorage';
import { axiosSecureBackend } from '../axios-authenticated';

class AuthStore {
  constructor() {
    this.store = new Map(Object.entries({
      authenticated: !!localStorage.getItem('JWT'),
      userRole: localStorage.getItem('USER_ROLE')
    }));

    this.listenToOtherTabsFiringLogout();
    this.updateAxiosJWTAuth(localStorage.getItem('JWT'));

    // listen to JWT changes in localStorage (globally, accross all tabs)
    autorun(() => {
      this.updateAxiosJWTAuth(localStorage.getItem('JWT'));
      this.store.set('authenticated', !!localStorage.getItem('JWT')); // all tabs respond to this.
      this.store.set('userRole', localStorage.getItem('USER_ROLE')); // all tabs respond to this.
    });
  }

  listenToOtherTabsFiringLogout() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'logout') {
        window.location.reload();
      }
    });
  }

  updateAxiosJWTAuth = (bearerToken) => {
    axiosSecureBackend.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
  }

  get loggedIn() {
    return this.store.get('authenticated');
  }

  get userRole() {
    return this.store.get('userRole');
  }

  login = action('LOGIN', (jwtToken, userRole) => {
    localStorage.setItem('JWT', jwtToken);    
    localStorage.setItem('USER_ROLE', userRole);
  })

  logout = action('LOGOUT', () => {
    localStorage.removeItem('USER_ROLE');
    localStorage.removeItem('JWT');
    localStorage.removeItem('JWT_EXPIRES');
    localStorage.setItem('logout', Date.now());
    window.location.reload();
  });
}

decorate(AuthStore, {
  store: observable,
  loggedIn: computed,
  userRole: computed,
});

export const store = new AuthStore();

window.authStore = store;