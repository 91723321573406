import 'mobx-react/batchingForReactDom'; // prevent issues with parent / child react element OOE
// import { spy } from 'mobx';
import React, { lazy, Suspense } from 'react';
import { Router } from 'react-router-dom';
import history from '../services/history';
import { SettingsContextProvider } from './settings/SettingsContext';
import { Page } from '@piingltd/piing-dashboard-ui';
const Routes = lazy(() => import('./routing/Routes'));

// // console log all mobx actions
// spy(event => {
//   if (event.type === "action") {
//     console.log(`${event.name} with args: `, event.arguments);
//   }
// })

function App() {

  return (
    <Suspense fallback={<Page.Connecting />}>
      <SettingsContextProvider>
        <Router history={history}>
          <Routes />
        </Router>
      </SettingsContextProvider>
    </Suspense>
  );
}

export default App;