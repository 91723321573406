export const translation = {
  "connecting": "Conectando...",
  "offline": {
    "title": "Vaya",
    "message": "No hay ningún juego ejecutándose en este momento"
  },
  "video": {
    "tapToUnmute": "Pulsa para activar el sonido",
    "tryVideoAgain": "Reintentar vídeo"
  },
  "countdown": {
    "soon": "Muy pronto…",
    "aboutToStart": "Comenzamos en breve…",
    "kickOff": "Empezamos"
  },
  "join": {
    "instruction": "¡Únete al concurso!",
    "joinInNow": "¡Entra ahora!",
    "screenName": "Nombre en pantalla",
    "enterScreenName": "Escribe tu nombre",
    "button": {
      "join": "Únete",
      "next": "Siguiente"
    },
    "chooseTeam": "Elige equipo",
    "hello": "Hola",
    "vs": "– contra –",
    "player": "Jugador",
    "players": "Jugadores"
  },
  "form": {
    "name": "Nombre",
    "email": "Correo",
    "mobile": "Móvil",
    "iAgreeToThe": "Estoy de acuerdo con los <0>{{terms}}</0>",
    "terms": "términos y condiciones",
    "termsLink": "https://piing.events/terms-quiiz",
    "privacyPolicy": "Política de privacidad",
    "privacyPolicies": "Políticas de privacidad",
    "privacyLink": "https://piing.events/privacy",
    "ok": "Aceptar"
  },
  "errorBoundary": {
    "connectionErrorTitle": "Lo sentimos, tuvimos problemas para conectarte.",
    "connectionError": "Inténtalo <0>{{tryAgain1}}</0>.",
    "notConnectionErrorTitle": "Perdón, algo salió mal.",
    "notConnectionError": "<0>{{tryAgain2}}</0> o <1>{{contactUs}}</1> para informar este problema.",
    "tryAgain1": "de nuevo",
    "tryAgain2": "Vuelva a intentarlo",
    "contactUs": "comuníquese con nosotros",
    "emailSubject": "Reportar un problema con Quiiz",
    "emailBody": "Describa lo que sucedió a continuación"
  },
  "validation": {
    "nameTaken": "Ese nombre ya existe",
    "problemJoining": "Ha habido un problema al unirse al juego",
    "notReadyForPlayers": "Aún no estamos listos",
    "checkName": "Por favor, comprueba tu nombre",
    "checkEmail": "Por favor, comprueba tu correo",
    "checkMobile": "Por favor, comprueba tu número de móvil",
  },
  "leaderboard": {
    "teamsLeaderboard": "Clasificación por equipos",
    "playersLeaderboard": "Clasificación de jugadores",
    "team": "Equipo",
    "name": "Nombre",
    "score": "Puntos",
    "bonus": "Bonus",
    "wins": "ha ganado!",
    "tab": {
      "topPlayers": "Mejores jugadores",
      "topTeams": "Mejores equipos",
      "yourTeam": "Tu equipo",
    }
  },
  "score": {
    "q": "Pregunta ",
    "correct": "Correcto",
    "incorrect": "Incorrecto",
    "speedBonus": "Bonificación por velocidad",
    "team": "Equipo",
    "overall": "Total",
    "score": "Puntuación",
    "rank": "Posición",
  },
  "question": {
    "question": "Pregunta",
    "milliseconds": "ms",
  }
};