import axios from 'axios';
import axiosRetry from 'axios-retry';
import { merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import { setupTagManager } from '../../tracking/tag-manager';
import switchLanguage from '../../translations/switch-language';
import PiingConnectionError from '../error/PiingConnectionError';
import { ErrorMessageTypes, withPiingErrorBoundary } from '../error/PiingErrorBoundary';
import { Page } from '@piingltd/piing-dashboard-ui';

export const SettingsContext = React.createContext();
SettingsContext.displayName = 'SettingsContext';

export const SettingsContextProvider = withPiingErrorBoundary(({ children }) => {
  const [queryStringOverrides, setQueryStringOverrides] = useState(null);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(null);

  if (error) {
    throw new PiingConnectionError(`Error loading settings`);
  }

  useEffect(() => {
    if (queryStringOverrides != null) {
      const axiosClient = axios.create();
      axiosRetry(axiosClient);

      axiosClient
        .get(`${process.env.REACT_APP_BACKEND_HOSTNAME}/api/settings`, {
          'axios-retry': {
            retries: process.env.NODE_ENV === 'production' ? 20 : 2,
            retryDelay: (retryCount) => {
              return retryCount * 500;
            }
          }
        })
        .then(({ data: settingsData }) => {
          const mergedSettings = merge(settingsData, queryStringOverrides);
          setSettings(mergedSettings);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [queryStringOverrides]);

  useEffect(() => {
    if (settings === null) return;
    switchLanguage(settings.language);
    setupTagManager();
  }, [settings]);

  useEffect(() => {
    const ballBudgetQS = new URLSearchParams(window.location.search).get('ballBudget');
    setQueryStringOverrides(ballBudgetQS ? { playout: { ballBudget: parseInt(ballBudgetQS) } } : {});
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {settings && children}
      {!settings && <Page.Connecting />}
    </SettingsContext.Provider>
  );
}, ErrorMessageTypes.CONNECTION_ERROR);