import React from 'react';
import styles from './styles/piing-error-boundary.module.scss';
import * as Sentry from '@sentry/react';
import { Trans, useTranslation } from 'react-i18next';
import { Display, Page, Icon } from '@piingltd/piing-dashboard-ui';

export const ErrorMessageTypes = {
  GENERAL_ERROR: 'general_error',
  CONNECTION_ERROR: 'connection_error'
}

const PiingErrorBoundaryFallback = props => {
  const { error, componentStack, eventId, resetError, app = 'Quiiz' } = props;
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  }

  // const handleContinue = () => {
  //   resetError();
  // }

  const contactUsLink = <a href={encodeURI(`mailto:feedback@piing.events?subject=${t('errorBoundary.emailSubject')} | ref: ${eventId}&body=${t('errorBoundary.emailBody')}`)}>{t('errorBoundary.contactUs')}</a>;
  const tryAgainLink1 = <button className={'link'} onClick={handleReload}>{t('errorBoundary.tryAgain1')}</button>;
  const tryAgainLink2 = <button className={'link'} onClick={handleReload}>{t('errorBoundary.tryAgain2')}</button>;

  return (
    <Page.Container>
      <Page.Screen>
        <Page.Content>
          <Page.Stack>
            <Display.SystemMessage
              title={error.name === 'PiingConnectionError' ? t('errorBoundary.connectionErrorTitle') : t('errorBoundary.notConnectionErrorTitle')}
              message={
                <>
                  {
                    error.name === 'PiingConnectionError' &&
                    <Trans
                      i18nKey="errorBoundary.connectionError"
                      values={{ tryAgain1: t('errorBoundary.tryAgain1') }}
                      components={[tryAgainLink1]}
                    />
                  }
                  {
                    error.name !== 'PiingConnectionError' &&
                    <Trans
                      i18nKey="errorBoundary.notConnectionError"
                      values={{ tryAgain2: t('errorBoundary.tryAgain2'), contactUs: t('errorBoundary.contactUs') }}
                      components={[tryAgainLink2, contactUsLink]}
                    />
                  }
                </>
              }
              icon={Icon.InfoIcon.ALERT}
            />
            <Page.Pane limitWidth>
              {
                process.env.NODE_ENV !== 'production' && (
                  <div className={styles.harness}>
                    <div className={styles.message}>{error.message}</div>
                    <div className={styles.stackTrace}>{componentStack}</div>
                  </div>
                )
              }
            </Page.Pane>
          </Page.Stack>
        </Page.Content>
      </Page.Screen >
    </Page.Container>
  )
};

function PiingErrorBoundary({ children, ...props }) {
  return <Sentry.ErrorBoundary fallback={(fallbackProps) => <PiingErrorBoundaryFallback {...fallbackProps} {...props} />}>{children}</Sentry.ErrorBoundary>
}

export default PiingErrorBoundary;


// Modified from example here: https://reactjs.org/docs/higher-order-components.html
export const withPiingErrorBoundary = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <PiingErrorBoundary {...this.props}>
          <WrappedComponent {...this.props} />
        </PiingErrorBoundary>
      )
    }
  }
};