import numbro from 'numbro';
import { localisationSettings } from './i18n';

const languageData = {
  'en-GB': require('numbro/languages/en-GB'),
  'de-DE': require('numbro/languages/de-DE'),
  'es-ES': require('numbro/languages/es-ES'),
  'sl-SI': require('numbro/languages/sl'),
  'tr-TR': require('numbro/languages/tr-TR'),
  'pl-PL': require('numbro/languages/pl-PL')
}

const applyOrdinalIndicatorToNumber = (lang, number) => {
  switch (lang) {
    case 'es-ES':
      return `${number}º`;
    default:
      return numbro(number).format({ output: "ordinal" })
  }
}

export const setupNumbro = (language) => {
  numbro.registerLanguage(languageData[language]);
  numbro.setLanguage(language);
}

export const ordinal = (number, forceShowOrdinal = false) => {
  const ignoreLanguages = ['de-DE', 'sl-SI'];
  const currentLanguage = localisationSettings.language;
  const ignore = ignoreLanguages.includes(currentLanguage);
  return ignore && !forceShowOrdinal ? `${number}` : applyOrdinalIndicatorToNumber(currentLanguage, number);
}