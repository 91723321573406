import { setupI18N } from './i18n';
import { setupNumbro } from './numbro-helpers';

const SUPPORTED_LANGUAGES = ['en-GB', 'de-DE', 'es-ES', 'sl-SI', 'tr-TR', 'pl-PL'];
const getLanguageCountry = language => language.substr(0, 2);

const switchLanguage = async (language) => {
  if (!SUPPORTED_LANGUAGES.includes(language)) {
    throw new Error(`Language ${language} is not supported.`);
  }
  document.documentElement.setAttribute('lang', getLanguageCountry(language)); // cookie-script uses this for translation switch

  setupNumbro(language);
  setupI18N(language);
}

export default switchLanguage;