export const translation = {
  connecting: "Łączenie...",
  offline: {
    title: "Przepraszamy!",
    message: "W tej chwili nie ma aktywnego quizu"
  },
  video: {
    tapToUnmute: "Dotknij, aby wyłączyć wyciszenie",
    tryVideoAgain: "Ponów próbę odtworzenia wideo"
  },
  countdown: {
    soon: "Wkrótce...",
    aboutToStart: "Wkrótce się zacznie...",
    kickOff: "Rozpocznij"
  },
  join: {
    instruction: "Dołącz do quizu!",
    joinInNow: "Dołącz teraz!",
    screenName: "Nazwa użytkownika",
    enterScreenName: "Wprowadź swoją nazwę użytkownika",
    button: {
      join: "Dołącz",
      next: "Dalej"
    },
    chooseTeam: "Wybierz zespół, do którego chcesz dołączyć",
    hello: "Cześć",
    vs: "– vs –",
    player: "Gracz",
    players: "Gracze"
  },
  form: {
    name: "Imię",
    email: "Email",
    mobile: "Telefon komórkowy",
    iAgreeToThe: "Zgadzam się na <0>{{terms}}</0>",
    terms: "warunki & regulamin",
    termsLink: "https://piing.events/terms-quiiz",
    privacyPolicy: "Polityka prywatności",
    privacyPolicies: "Polityki prywatności",
    privacyLink: "https://piing.events/privacy",
    ok: "OK"
  },
  errorBoundary: {
    connectionErrorTitle: "Przepraszamy, mieliśmy problem z połączeniem",
    connectionError: "Proszę <0>{{tryAgain1}}</0>",
    notConnectionErrorTitle: "Przepraszamy, coś poszło nie tak",
    notConnectionError: "Proszę <0>{{tryAgain2}}</0> lub <1>{{contactUs}}</1> aby zgłosić ten problem",
    tryAgain1: "spróbuj ponownie",
    tryAgain2: "spróbuj ponownie",
    contactUs: "skontaktuj się z nami",
    emailSubject: "Zgłoś problem związany z Quiiz",
    emailBody: "Proszę opisz, co się stało poniżej"
  },
  validation: {
    nameTaken: "Ta nazwa jest już zajęta!",
    problemJoining: "Wystąpił problem z dołączeniem do gry",
    notReadyForPlayers: "Nie jesteśmy jeszcze gotowi na graczy",
    checkName: "Proszę sprawdź swoje imię",
    checkEmail: "Proszę sprawdź swój adres email",
    checkMobile: "Proszę sprawdź swój numer telefonu",
  },
  leaderboard: {
    teamsLeaderboard: "Ranking drużyn",
    playersLeaderboard: "Ranking graczy",
    team: "Drużyna",
    name: "Imię",
    score: "Wynik",
    bonus: "Bonus",
    wins: "wygrywa!",
    tab: {
      topPlayers: "Najlepsi gracze",
      topTeams: "Najlepsze drużyny",
      yourTeam: "Twoja drużyna",
    }
  },
  score: {
    q: "P",
    correct: "Dobra odpowiedź",
    incorrect: "Zła odpowiedź",
    speedBonus: "Bonus za szybkość",
    team: "Drużyna",
    overall: "Podsumowanie",
    score: "Wynik",
    rank: "Miejsce",
  },
  question: {
    question: "Pytanie",
    milliseconds: "ms",
  }
};