export const translation = {
  "connecting": "Bağlanılıyor...",
  "offline": {
    "title": "Üzgünüz!",
    "message": "Şu anda çalışan bir test yok"
  },
  "video": {
    "tapToUnmute": "Sesi açmak için dokunun",
    "tryVideoAgain": "Videoyu yeniden dene"
  },
  "countdown": {
    "soon": "Yakında...",
    "aboutToStart": "Yakında başlıyor...",
    "kickOff": "Başla"
  },
  "join": {
    "instruction": "Teste katıl!",
    "joinInNow": "Şimdi katıl!",
    "screenName": "Ekran adı",
    "enterScreenName": "Ekran adınızı girin",
    "button": {
      "join": "Katıl",
      "next": "Sonraki"
    },
    "chooseTeam": "Katılacak bir takım seç",
    "hello": "Merhaba",
    "vs": "– Karşısında –",
    "player": "Oyuncu",
    "players": "Oyuncular"
  },
  "form": {
    "name": "İsim",
    "email": "E posta",
    "mobile": "Cep telefonu",
    "iAgreeToThe": "Kabul ediyorum <0>{{terms}}</0>",
    "terms": "şartlat& koşullar",
    "termsLink": "https://piing.events/terms-quiiz",
    "privacyPolicy": "Gizlilik Politikası",
    "privacyPolicies": "Gizlilik politikaları",
    "privacyLink": "https://piing.events/privacy",
    "ok": "Tamam"
  },
  "errorBoundary": {
    "connectionErrorTitle": "Üzgünüz, size bağlanırken sorun yaşadık",
    "connectionError": "Üzgünüz, sizi bağlamakta sorun yaşadık. Lütfen <0>{{tryAgain1}}</0>.",
    "notConnectionErrorTitle": "Üzgünüz, bir şeyler ters gitti",
    "notConnectionError": "Üzgünüz, bir şeyler ters gitti.<br />Lütfen <0>{{tryAgain2}}</0> veya <1>{{contactUs}}</1> bu sorunu bildirmek için.",
    "tryAgain1": "yeniden dene",
    "tryAgain2": "yeniden dene",
    "contactUs": "bizimle iletişime geç",
    "emailSubject": "Report a problem with Quiiz / Quiiz ile ilgili sorunu bildir",
    "emailBody": "Aşağıda ne olduğunu açıklayın"
  },
  "validation": {
    "nameTaken": "Bu isim alınmıştır!",
    "problemJoining": "Oyuna katılımda bir sorun yaşandı",
    "notReadyForPlayers": "Henüz oyuncular için hazır değiliz",
    "checkName": "Lütfen adınızı kontrol edin",
    "checkEmail": "Lütfen e-postanızı kontrol edin",
    "checkMobile": "Lüften cep telefon numaranısı kontrol edin",
  },
  "leaderboard": {
    "teamsLeaderboard": "Takımlar lider tablosu",
    "playersLeaderboard": "Oyuncular lider tablosu",
    "team": "Takım",
    "name": "isim",
    "score": "Puan",
    "bonus": "Bonus",
    "wins": "kazandı!",
    "tab": {
      "topPlayers": "En iyi oyuncular",
      "topTeams": "En iyi takımlar",
      "yourTeam": "Sizin takımınız",
    }
  },
  "score": {
    "q": "S",
    "correct": "Doğru",
    "incorrect": "Yanlış",
    "speedBonus": "Hız bonusu",
    "team": "Takım",
    "overall": "Genel",
    "score": "Puan",
    "rank": "Sıra",
  },
  "question": {
    "question": "Soru",
    "milliseconds": "ms",
  }
};